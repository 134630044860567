import { Constants } from './../constants';
import { Observable } from 'rxjs/index';
import {Injectable} from '@angular/core';
import {AngularFirestore} from 'angularfire2/firestore';
import {CRUDProvider} from '../CRUDProvider';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, map, tap } from 'rxjs/operators';
import { ExcelBooking } from '../../models/excelBooking';

@Injectable({
  providedIn: 'root'
})
export class ExcelBookingService {
  // private url = "https://dypis.uber.space/bookings";
  private url = `${Constants.excelUrl}bookings`

  bookings: ExcelBooking[] = [];

  constructor(private http: HttpClient) { }

  loadBookings(): Observable<ExcelBooking[]>  {
    return this.http.get<ExcelBooking[]>(this.url);
  }

}

