import {Injectable} from '@angular/core';
import {FirebaseApp} from 'angularfire2';
import {Storage} from '@ionic/storage';


/*
  Generated class for the NotificationProvider provider.

  See https://angular.io/guide/dependency-injection for more info on providers
  and Angular DI.
*/
@Injectable({providedIn: 'root'})
export class NotificationProvider {

    private messaging;
    private unsubscribeOnTokenRefresh = () => {};

    constructor(
        private storage: Storage,
        private app: FirebaseApp
    ) {
        this.messaging = app.messaging();
        navigator.serviceWorker.register('service-worker.js').then((registration) => {
            this.messaging.useServiceWorker(registration);
            // this.disableNotifications()
            this.enableNotifications();
        });
    }

    public enableNotifications() {
        console.log('Requesting permission...');
        return this.messaging.requestPermission().then(() => {
            console.log('Permission granted');
            // token might change - we need to listen for changes to it and update it
            this.setupOnTokenRefresh();
            return this.updateToken();
        });
    }

    public disableNotifications() {
        this.unsubscribeOnTokenRefresh();
        this.unsubscribeOnTokenRefresh = () => {
        };
        return this.storage.set('fcmToken', '').then();
    }

    private updateToken() {
        return this.messaging.getToken().then((currentToken) => {
            if (currentToken) {
                // we've got the token from Firebase, now let's store it in the database
                console.log(currentToken);
                return this.storage.set('fcmToken', currentToken);
            } else {
                console.log('No Instance ID token available. Request permission to generate one.');
            }
        });
    }

    private setupOnTokenRefresh(): void {
        this.unsubscribeOnTokenRefresh = this.messaging.onTokenRefresh(() => {
            console.log('Token refreshed');
            this.storage.set('fcmToken', '').then(() => {
                this.updateToken();
            });
        });
    }

    receiveMessage() {
        this.messaging.onMessage((payload) => {
            console.log('Message received. ', payload);
            console.log(payload);
        });
    }
}
