import { GameProvider } from './services/games/games';
import {LOCALE_ID, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {RouteReuseStrategy} from '@angular/router';

import {IonicModule, IonicRouteStrategy} from '@ionic/angular';
import {SplashScreen} from '@ionic-native/splash-screen/ngx';
import {StatusBar} from '@ionic-native/status-bar/ngx';

import {AppComponent} from './app.component';
import {AppRoutingModule} from './app-routing.module';
import {IonicStorageModule} from '@ionic/storage';

import {AngularFirestoreModule} from 'angularfire2/firestore';
import {AngularFireAuthModule} from 'angularfire2/auth';
import {AngularFireModule} from 'angularfire2';

import {environment} from './environments/environment';
import {AuthProvider} from './services/auth/auth';
import {FineProvider} from './services/fine/fine';
import {UserProvider} from './services/user/user';
import {KegelAbendProvider} from './services/kegel-abend/kegel-abend';
import {AlertProvider} from './services/alert/alert';
import {ImageProvider} from './services/image/image';
import {KegelAbendFineProvider} from './services/kegel-abend-fine/kegel-abend-fine';
import {AppointmentProvider} from './services/appointment/appointment';
import {NoticeProvider} from './services/notice/notice';
import {FirebaseUserProvider} from './services/firebase-user/firebase-user';
import {SurveyProvider} from './services/survey/survey';
import {NotificationProvider} from './services/notification/notification';
import {PaymentProvider} from './services/payment/payment';
import {VoteProvider} from './services/vote/vote';
import {FeedProvider} from './services/feed/feed';
import {StatsProvider} from './services/stats/stats';
import {UserStatsProvider} from './services/user-stats/user-stats';
import {registerLocaleData} from '@angular/common';
import localeDe from '@angular/common/locales/de';
import {AvatarModule} from 'ngx-avatar';
import {AuthGuard} from './auth.guard';
import {UserAvatarModule} from './user-avatar/user-avatar.module';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';


registerLocaleData(localeDe, 'de');
import 'hammerjs';
import {MAT_DATE_LOCALE, MatFormFieldModule, MatInputModule, MatNativeDateModule} from '@angular/material';
import { ExcelAccountService } from './services/excelAccount/excel-account.service';
import { ExcelBookingService } from './services/excelBooking/excel-booking.service';


@NgModule({
    declarations: [AppComponent],
    entryComponents: [],
    imports: [
        BrowserModule,
        IonicModule.forRoot({mode: 'md'}),
        AppRoutingModule,
        AngularFirestoreModule,
        AngularFireAuthModule,
        AngularFireModule.initializeApp(environment.firebase),
        IonicStorageModule.forRoot(),
        AvatarModule,
        UserAvatarModule,
        BrowserAnimationsModule,
        MatNativeDateModule,
        MatInputModule,
        MatFormFieldModule
],
    providers: [
        StatusBar,
        SplashScreen,
        {provide: RouteReuseStrategy, useClass: IonicRouteStrategy},
        {provide: LOCALE_ID, useValue: 'de-DE'},
        {provide: MAT_DATE_LOCALE, useValue: 'de-DE'},
        AuthProvider,
        FineProvider,
        UserProvider,
        KegelAbendProvider,
        AlertProvider,
        ImageProvider,
        KegelAbendFineProvider,
        AppointmentProvider,
        NoticeProvider,
        FirebaseUserProvider,
        NotificationProvider,
        SurveyProvider,
        VoteProvider,
        PaymentProvider,
        FeedProvider,
        StatsProvider,
        UserStatsProvider,
        AuthGuard,
        GameProvider,
        ExcelAccountService,
        ExcelBookingService,
        ExcelAccountService
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
