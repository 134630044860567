import { Constants } from './../constants';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ExcelAccount } from '../../models/excelAccount';

@Injectable({
  providedIn: 'root'
})
export class ExcelAccountService {
  // private url = "https://dypis.uber.space/accounts"
  private url = `${Constants.excelUrl}accounts`


  accounts: ExcelAccount[] = [];

  constructor(private http: HttpClient) {}

loadAccounts(): Observable<ExcelAccount[]>  {
  return this.http.get<ExcelAccount[]>(this.url);
}

removeAccount(account: ExcelAccount) {

}
}
