import {Injectable} from '@angular/core';
import {CRUDProvider} from '../CRUDProvider';
import {Feed} from '../../models/feed';
import {AngularFirestore} from 'angularfire2/firestore';
import {CollectionName} from '../../models/collectionEnum';
import * as firebase from 'firebase';
import {map, take, takeUntil} from 'rxjs/operators';
import {ImageProvider} from '../image/image';
import {AngularFireAuth} from 'angularfire2/auth';
import * as moment from 'moment';
import {KegelAbend} from '../../models/kegelabend';

/*
  Generated class for the FeedProvider provider.

  See https://angular.io/guide/dependency-injection for more info on providers
  and Angular DI.
*/
@Injectable({providedIn: 'root'})
export class FeedProvider {

    loaded = false;
    comment: String;

    crudProvider: CRUDProvider<Feed>;
    crudProviderAny: CRUDProvider<any>;

    feedList: Feed[] = [];
    filteredFeedList: Feed[] = [];


    constructor(private afs: AngularFirestore, private afAuth: AngularFireAuth) {
        this.crudProvider = new CRUDProvider<Feed>(CollectionName.Feed.toString(), afs, afAuth);
        this.loadFeedList();

    }

    public async loadFeedList() {
        this.crudProvider.getElementList().subscribe(async feedList => {
                this.feedList = [];
                for (const feed of feedList) {
                    try { // Error-Handling für Feeds ohne collectionName
                        this.feedList.push(feed);
                        // Erstellt eine Liste der verschiedenen Feed-Objekte
                        this.crudProviderAny = new CRUDProvider<any>(feed.collectionName.toString(), this.afs, this.afAuth);
                        await this.crudProviderAny.getElement(feed.referencedId).pipe(take(1)).toPromise().then(element => {
                            feed.referencedObject = element;
                            // console.log(element);
                        });
                    } catch (e) {
                        // console.log('Something is undefined of feed');
                    }
                }
                // Sortiert die Feedlist: Neuester Feed am Anfang
                this.feedList.sort((feed1, feed2) => feed1.created < feed2.created ? 1 : -1);
                this.loaded = true;
                // console.log('Feedlist vollständig geladen!');
            });
    }


    public loadFilteredFeedList(lastPresent: string) {
        if (lastPresent != null) {
            this.afs.collection(CollectionName.Feed.toString(), ref =>
                ref.orderBy('created').startAt(lastPresent)).valueChanges()
                .subscribe((data: any) => {
                    this.filteredFeedList = data;
                });
        }
    }

    public getCountOfCollectionFeed(collectionName: CollectionName): number {
        let counter = 0;
        if (collectionName != null) {
            this.filteredFeedList.forEach(feed => {
                if (feed.collectionName === collectionName) {
                    counter++;
                }
            });
        }
        return counter;
    }

    public removeCollectionFromFilteredList(collectionName: CollectionName) {
        if (collectionName != null) {
            const newFilteredList: Feed[] = [];
            this.filteredFeedList.forEach(feed => {
                if (feed.collectionName !== collectionName) {
                    newFilteredList.push(feed);
                }
            });
            this.filteredFeedList = newFilteredList;
        }
    }

    public addFeed(feed: Feed) {
        feed.created = firebase.firestore.FieldValue.serverTimestamp();
        this.crudProvider.addElement(feed);
    }

    public updateFeed(feed: Feed) {
        this.crudProvider.updateElement(feed);
    }

    deleteFeed(id: string) {
        this.crudProvider.deleteElement(id);
    }

    addNewComment() {
        this.comment = '';
    }

    getUpcomingKegelAbend(): Promise<KegelAbend[]> {
        return this.afs.collection<KegelAbend>(CollectionName.KegelAbend, ref => ref.where('date', '>', moment().format()))
            .valueChanges().pipe(take(1)).toPromise();
    }

}
