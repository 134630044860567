import {Injectable} from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router} from '@angular/router';
import {Observable} from 'rxjs';
import {AngularFireAuth} from 'angularfire2/auth';
import {map, take, tap} from 'rxjs/operators';
import {AuthProvider} from './services/auth/auth';

@Injectable({
    providedIn: 'root'
})
export class AuthGuard implements CanActivate {

    constructor(private router: Router, private angularFireAuth: AngularFireAuth, private authProvider: AuthProvider) {
    }

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        if (this.authProvider.isLoggedIn()) {
            return true;
        }
        return this.angularFireAuth.authState.pipe(
            take(1),
            map(user => !!user),
            tap(loggedIn => {
            if (!loggedIn) {
                this.router.navigate(['/login']);
            }
        }));
    }
}
