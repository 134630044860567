import {Injectable} from '@angular/core';
import {AngularFireAuth} from 'angularfire2/auth';
import {User} from '../../models/user';
import {AngularFirestore} from 'angularfire2/firestore';
import {FirebaseUser} from '../../models/firebaseUser';
import {FirebaseUserProvider} from '../firebase-user/firebase-user';
import {CollectionName} from '../../models/collectionEnum';
import {Role} from '../../models/roles.enum';
import * as firebase from 'firebase';
import {FeedProvider} from '../feed/feed';
import {AlertProvider} from '../alert/alert';
import {BehaviorSubject} from 'rxjs/BehaviorSubject';
import {take} from 'rxjs/operators';

/*
 Generated class for the AuthProvider provider.

 See https://angular.io/guide/dependency-injection for more info on providers
 and Angular DI.
 */
@Injectable({providedIn: 'root'})
export class AuthProvider {

    oldRealTimeDb = firebase.database();

    userSubject: BehaviorSubject<User> = new BehaviorSubject<User>(null);

    firebaseUserSubject: BehaviorSubject<FirebaseUser> = new BehaviorSubject<FirebaseUser>(null);

    userId: string;

    currentPermission: Role;
    private isAuthenticated = false;

    constructor(private afs: AngularFirestore, public angularFireAuth: AngularFireAuth, private firebaseUserProvider: FirebaseUserProvider,
                public feedProvider: FeedProvider, public alertProvider: AlertProvider) {
        this.loadUser();
    }

    // Wird aufgerufen, wenn ein Nutzer die App öffnet
    onConnect(userId: string) {
        const onlineRef = this.oldRealTimeDb.ref('.info/connected'); // Get a reference to the list of connections
        onlineRef.on('value', snapshot => {
            // console.log('onlineTest');
            // Let's also create a key in our real-time database
            // The value is set to 'online'
            // console.log(snapshot.val());
            if (snapshot.val()) {
                this.oldRealTimeDb.ref(`/status/${userId}`).set('online');
            }
        });
    }

    // Wird aufgerufen, wenn ein Nutzer die App verlässt
    onDisconnect(userId: string) {
        const onlineRef = this.oldRealTimeDb.ref('.info/connected'); // Get a reference to the list of connections
        onlineRef.on('value', snapshot => {
            // console.log('offlineTest');
            this.oldRealTimeDb
                .ref(`/status/${userId}`)
                .onDisconnect() // Set up the disconnect hook
                .set('offline'); // The value to be set for this key when the client disconnects
        });
    }

    // Lade Feed-Liste und berücksichtige neue Elemente basierend darauf, wann der Nutzer zuletzt online war
    loadPresentBasedFeed(authUser) {
        // TODO Badges (Anzahl neuer Feeds seit letzter Anmedlung) werden aktuell nicht verwendet, sollte eventuell entfernt werden
        this.afs.doc('userPresence/' + authUser.uid)
            .valueChanges()
            .subscribe((data: any) => {
                if (data != null) {
                    this.feedProvider.loadFilteredFeedList(data.lastPresent);
                }
            });
    }

    // Läd innerhalb der App erstellten Nutzer für aktuell eingeloggten Nutzer
    loadUser() {
        this.angularFireAuth.authState.subscribe(authUser => {
            if (authUser != null) {
                this.firebaseUserProvider.loadFirebaseUserList();
                this.isAuthenticated = true;
                this.loadPresentBasedFeed(authUser);

                this.afs.collection<FirebaseUser>(CollectionName.FirebaseUser.toString(), ref =>
                    ref.where('firebaseId', '==', authUser.uid)).valueChanges()
                    .subscribe(dataList => {
                        dataList.forEach(firebaseUser => {
                            this.firebaseUserSubject.next(firebaseUser);
                            this.userId = firebaseUser.userId;
                            if (firebaseUser.userId != null) {
                                this.afs.collection<User>(CollectionName.User.toString()).doc<User>(firebaseUser.userId)
                                    .valueChanges().subscribe(user => {
                                    this.userSubject.next(user);
                                });
                            }
                        });
                        // Checks if value is not null or undefined
                        if (!!this.firebaseUserSubject.getValue()) {
                            this.onConnect(this.firebaseUserSubject.getValue().firebaseId);
                            this.onDisconnect(this.firebaseUserSubject.getValue().firebaseId);
                        }
                    });
            }
        });
    }

    login(username, password): Promise<any> {
        return this.angularFireAuth.auth.signInWithEmailAndPassword(username, password)
            .then((user) => {
                if (true) { // user.emailVerified
                    // console.log('email verified');
                    // Redirect the user here
                }
                /*else {
                         console.log('email not verified');
                         // Tell the user to have a look at its mailbox
                       } */
                return user;
            });
    }

    register(email, name, password): Promise<any> {
        return this.angularFireAuth.auth.createUserWithEmailAndPassword(email, password)
            .then((res) => {
                this.sendEmailVerification();
                // console.log(res.user.uid);
                const firebaseUser: FirebaseUser = {name: name, firebaseId: res.user.uid};
                this.firebaseUserProvider.addUser(firebaseUser);
            });
    }

    sendEmailVerification() {
        this.angularFireAuth.authState.subscribe(user => {
            user.sendEmailVerification()
                .then(() => {
                    // console.log('email sent');
                });
        });
    }

    sendPassword(email) {
        this.angularFireAuth.auth.sendPasswordResetEmail(email)
            .then(() => {
                // console.log('email sent');
            });
    }

    logout(): Promise<any> {
        return this.angularFireAuth.auth.signOut();
    }

    public isLoggedIn(): boolean {
        return this.isAuthenticated;
    }

    getAuthState() {
        return this.angularFireAuth.authState;
    }

    ///// Role-based Authorization //////

    /*loggedInUserIsGuest():boolean{
      return this.isGuest(this.userSubject.getValue());
    }*/

    loggedInUserIsMember() {
        return this.isMember(this.userSubject.getValue());
    }

    loggedInUserIsController() {
        return this.isController(this.userSubject.getValue());
    }

    /*loggedInUserIsAdmin(){
      return this.isAdmin(this.userSubject.getValue());
    }*/


    isGuest(user: User): boolean {
        const allowed = [Role.GUEST.toString()];
        this.currentPermission = Role.GUEST;
        return this.checkAuthorization(allowed, user);

    }

    isMember(user: User): boolean {
        const allowed = [Role.ADMIN.toString(), Role.CONTROLLER.toString(), Role.MEMBER.toString()];
        this.currentPermission = Role.MEMBER;
        return this.checkAuthorization(allowed, user);
    }

    isController(user: User): boolean {
        const allowed = [Role.ADMIN.toString(), Role.CONTROLLER.toString()];
        this.currentPermission = Role.CONTROLLER;
        return this.checkAuthorization(allowed, user);
    }

    isAdmin(user: User): boolean {
        const allowed = [Role.ADMIN.toString()];
        this.currentPermission = Role.ADMIN;
        return this.checkAuthorization(allowed, user);
    }


// determines if user has matching role
    checkAuthorization(allowedRoles: string[], user: User): boolean {
        if (user == null) {
            return false;
        } else {
            let hasRole = false;
            allowedRoles.forEach(role => {
                user.roleList.forEach(userRole => {
                    if (userRole.role.toString() === role) {
                        hasRole = true;
                    }
                });
            });
            return hasRole;
        }
    }
}
