import {Injectable} from '@angular/core';
import {AngularFirestore} from 'angularfire2/firestore';
import {CollectionName} from '../../models/collectionEnum';
import {CRUDProvider} from '../CRUDProvider';
import * as isSameDay from 'date-fns/is_same_day';
import * as moment from 'moment';
import {Appointment} from '../../models/appointment';
import {AngularFireAuth} from 'angularfire2/auth';

/*
 Generated class for the CalendarProvider provider.

 See https://angular.io/guide/dependency-injection for more info on providers
 and Angular DI.
 */
@Injectable({providedIn: 'root'})
export class AppointmentProvider {

    crudProvider: CRUDProvider<Appointment>;

    appointmentList: Appointment[] = [];

    public filteredAppointmentList: Appointment[] = [];
    private filterDate: Date;

    // options: CalendarComponentOptions;
    // dates: Array<DayConfig> = [];


    constructor(private afs: AngularFirestore, private afAuth: AngularFireAuth) {
        this.crudProvider = new CRUDProvider<Appointment>(CollectionName.Appointment.toString(), afs, afAuth);
        this.loadAppointmentList();
    }

    public loadAppointmentList() {
        this.crudProvider.getElementList().subscribe(appointmentList => {
            this.appointmentList = [];
            appointmentList.forEach(appointment => {
                this.appointmentList.push(appointment);
            });
            /*this.options = {};
            this.dates = this.fillUpCalendar(this.appointmentList);
            this.options.daysConfig = this.dates;
            this.options.from = new Date('2010-01-01T00:00:00.000Z');
            this.options.to = 0;
            if (this.filterDate != null) {
                this.updateFilteredAppointmentList(this.filterDate);
            } else {
                this.updateFilteredAppointmentList(new Date(moment().format()));
            }*/
        });
    }

    public updateFilteredAppointmentList(date: Date) {
        this.filterDate = date;
        this.filteredAppointmentList = this.appointmentList.filter(
            appointment => isSameDay(date, new Date(appointment.startDate))
        );
    }

    public addAppointment(appointment: Appointment) {
        return this.crudProvider.addElement(appointment);
    }

    deleteAppointment(id: string) {
        return this.crudProvider.deleteElement(id);
    }

    updateAppointment(appointment: Appointment) {
        this.crudProvider.updateElement(appointment);
    }

    /*fillUpCalendar(appointmentList: Appointment[]): Array<DayConfig> {
        const dateList: Array<DayConfig> = [];
        appointmentList.forEach(appointment => {
            dateList.push({date: new Date(appointment.startDate), subTitle: '●'});
        });
        console.log(dateList);
        return dateList;
    }*/

}
