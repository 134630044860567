import { Constants } from './../constants';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ExcelUser } from '../../models/excelUser';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ExcelUserService {
  // private url = "https://dypis.uber.space/users"
  private url = `${Constants.excelUrl}users`

  accounts: ExcelUser[] = [];

  constructor(private http: HttpClient) {}

loadUsers(): Observable<ExcelUser[]>  {
  return this.http.get<ExcelUser[]>(this.url);
}

}
