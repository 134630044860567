import {NgModule} from '@angular/core';
import {UserAvatarComponent} from './user-avatar.component';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {IonicModule} from '@ionic/angular';
import {AvatarModule} from 'ngx-avatar';
import {ImageViewerModalPageModule} from '../image-viewer-modal/image-viewer-modal.module';
import {ImageViewerModalPage} from '../image-viewer-modal/image-viewer-modal.page';


@NgModule({
    declarations: [UserAvatarComponent],
    imports: [
        CommonModule,
        FormsModule,
        IonicModule,
        AvatarModule,
        ImageViewerModalPageModule
    ],
    exports: [
        UserAvatarComponent
    ],
    entryComponents: [ImageViewerModalPage]

})
export class UserAvatarModule {
}
