import {Injectable} from '@angular/core';
import {Vote} from '../../models/vote';
import {CRUDProvider} from '../CRUDProvider';
import {CollectionName} from '../../models/collectionEnum';
import {AngularFirestore} from 'angularfire2/firestore';
import {Observable} from 'rxjs';
import {Survey} from '../../models/survey';
import {map} from 'rxjs/internal/operators';
import {AngularFireAuth} from 'angularfire2/auth';


/*
 Generated class for the VoteProvider provider.

 See https://angular.io/guide/dependency-injection for more info on providers
 and Angular DI.
 */
@Injectable({ providedIn: 'root' })
export class VoteProvider {

  crudProvider: CRUDProvider<Vote>;

  voteList: Vote[] = [];


  constructor(private afs: AngularFirestore, private afAuth: AngularFireAuth) {
    this.crudProvider = new CRUDProvider<Vote>(CollectionName.Vote.toString(), afs, afAuth);
  }

  public loadVoteList(survey: Survey, vote: Vote) {
    this.getVoteListBySurveyId(survey.id).subscribe(voteList => {
      const array = [];
      if (voteList != null) {
        voteList.forEach(userVote => {
          userVote.choiceList.forEach(data => {
            if (data.checked) {
              vote.choiceList.forEach(choice => {
                if (choice.description === data.description) {
                  choice.count = (choice.count || 0) + 1;
                  if (choice.userList == null) {
                    choice.userList = [];
                  }
                  choice.userList.push(userVote.userId);
                }
              });
            }
          });
          if (!survey.isOpen) {
            vote.choiceList.forEach(choice => {
              if (choice.description === survey.finalChoice) {
                const index = vote.choiceList.indexOf(choice);
                const copyChoice = vote.choiceList[0];
                vote.choiceList[0] = choice;
                vote.choiceList[index] = copyChoice;
              }
            });
          }
        });
      }
    });
  }

  public getVote(userId: string, surveyId: string): Observable<Vote[]> {
    return this.afs.collection<Vote>(CollectionName.Vote.toString(), ref =>
      ref.where('userId', '==', userId)
        .where('surveyId', '==', surveyId)).snapshotChanges().pipe(map(changes => {
      console.log(changes);
      // return changes;
      return changes.map(
       a => {
       const data = a.payload.doc.data() as Vote;
       data.id = a.payload.doc.id;
       return data;
       });
    }));
  }

  public getVoteListBySurveyId(surveyId: string): Observable < Vote[] > {
    return this.afs.collection<Vote>(CollectionName.Vote.toString(), ref =>
      ref.where('surveyId', '==', surveyId)).snapshotChanges().pipe(map(changes => {
        console.log(changes);
        // return changes;
        return changes.map(
         a => {
         const data = a.payload.doc.data() as Vote;
         data.id = a.payload.doc.id;
         return data;
         });
      }));
  }

  public addVote(vote: Vote) {
    this.removeCountAndUserList(vote);
    this.crudProvider.addElement(vote);
  }

  private removeCountAndUserList(vote: Vote) {
    vote.choiceList.forEach(choice => {
      choice.count = 0;
      choice.userList = [];
    });
  }

  public updateVote(vote: Vote) {
    if (vote.id != null) {
      this.removeCountAndUserList(vote);
      this.crudProvider.updateElement(vote);
    }
  }
}
