/**
 * Created by MartinThissen on 13.06.2018.
 */
import {CollectionName} from '../models/collectionEnum';
import {AbstractControl} from '@angular/forms';

export class Constants {

    static monthNames = ['Januar', 'Februar', 'März', 'April', 'Mai', 'Juni',
        'Juli', 'August', 'September', 'Oktober', 'November', 'Dezember'
    ];

    static excelUrl = "http://ec2-3-16-38-114.us-east-2.compute.amazonaws.com:8080/"
    // excelUrl = "http://localhost:8081/"

    // The Angular email validator accepts an email like "rob@example", perhaps because "rob@localhost" could be valid.
// The pureEmail regex does not accept "ryan@example" as a valid email address, which I think is a good thing.
// See: EMAIL_REGEXP from https://github.com/angular/angular.js/blob/ffb6b2fb56d9ffcb051284965dd538629ea9687a/src/ng/directive/input.js#L16
    static PURE_EMAIL_REGEXP = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

// Passwords should be at least 8 characters long and should contain one number, one character and one special character.
    static PASSWORD_REGEXP = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/;

    static onlyLettersRegEx = '^[a-zA-ZäöüÄÖÜß-\\s]+$';

    static getPages(isMember, isController) {
        return [
            {
                title: 'Home',
                component: '/home',
                icon: 'home',
                roleMethod: isMember,
            },
            {
                title: 'Kegelabend',
                component: '/kegelabendlist',
                icon: 'beer',
                roleMethod: isMember,
                collection: CollectionName.KegelAbend
            },
            {
                title: 'Kegelmitglieder',
                component: '/kegelmitglieder',
                icon: 'people',
                roleMethod: isMember
            },
            /*{
                title: 'Statistiken',
                component: 'StatsPage',
                icon: 'stats',
                roleMethod: isMember,
                collection: CollectionName.KegelAbend
            },
            {
                title: 'Kalender',
                component: 'CalendarPage',
                icon: 'calendar',
                roleMethod: isMember,
                collection: CollectionName.Appointment
            },
            {
                title: 'Abstimmungen',
                component: 'SurveyListPage',
                icon: 'clipboard',
                roleMethod: isMember,
                collection: CollectionName.Survey
            }, */
            {
                title: 'Finanzen',
                component: '/finance',
                icon: 'logo-euro',
                roleMethod: isController,
                collection: CollectionName.Payment
            },
            {
                title: 'Verwaltung',
                component: '/administration-tab',
                icon: 'construct',
                roleMethod: isController
            }
        ];
    }

    static MatchPassword(AC: AbstractControl) {
        const password = AC.get('password').value; // to get value in input tag
        const confirmPassword = AC.get('confirmPassword').value; // to get value in input tag
        if (password !== confirmPassword) {
            console.log('false');
            AC.get('confirmPassword').setErrors({MatchPassword: true});
        } else {
            console.log('true');
            return null;
        }
    }

    static groupBy(xs, key) {
        return xs.reduce(function (rv, x) {
            (rv[x[key]] = rv[x[key]] || []).push(x);
            return rv;
        }, {});
    }

    static standardDeviation(values) {
        const avg = Constants.average(values);

        const squareDiffs = values.map((value) => {
            const diff = value - avg;
            return diff * diff;
        });

        const avgSquareDiff = Constants.average(squareDiffs);

        return Math.sqrt(avgSquareDiff);
    }

    static average(data) {
        const sumAll = data.reduce((sum, value) => {
            return sum + value;
        }, 0);

        return sumAll / data.length;
    }

    static cdf(x, mean, variance) {
        return 0.5 * (1 + Constants.erf((x - mean) / (Math.sqrt(2 * variance))));
    }

    static erf(x) {
        // save the sign of x
        const sign = (x >= 0) ? 1 : -1;
        x = Math.abs(x);

        // constants
        const a1 =  0.254829592;
        const a2 = -0.284496736;
        const a3 =  1.421413741;
        const a4 = -1.453152027;
        const a5 =  1.061405429;
        const p  =  0.3275911;

        // A&S formula 7.1.26
        const t = 1.0 / (1.0 + p * x);
        const y = 1.0 - (((((a5 * t + a4) * t) + a3) * t + a2) * t + a1) * t * Math.exp(-x * x);
        return sign * y; // erf(-x) = -erf(x);
    }
}
