import {Injectable} from '@angular/core';
import {CRUDProvider} from '../CRUDProvider';
import {Survey} from '../../models/survey';
import {CollectionName} from '../../models/collectionEnum';
import {AngularFirestore} from 'angularfire2/firestore';
import * as moment from 'moment';
import {VoteProvider} from '../vote/vote';
import {Vote} from '../../models/vote';
import {Identifiable} from '../../models/identifiable';
import {AngularFireAuth} from 'angularfire2/auth';

/*
 Generated class for the SurveyProvider provider.

 See https://angular.io/guide/dependency-injection for more info on providers
 and Angular DI.
 */
@Injectable({providedIn: 'root'})
export class SurveyProvider {

    constructor(private afs: AngularFirestore, private voteProvider: VoteProvider, private afAuth: AngularFireAuth) {
        this.crudProvider = new CRUDProvider<Survey>(CollectionName.Survey.toString(), afs, afAuth);
        this.loadSurveyList();
    }

    crudProvider: CRUDProvider<Survey>;

    surveyList: Survey[] = [];

    private static getTime(date?: Date) {
        return date != null ? date.getTime() : 0;
    }

    static sortDateList<T extends Identifiable>(dateList: T[]): T[] {
        return dateList.sort((element1, element2) => {
            return -1 * (this.getTime(new Date(element1.date)) - this.getTime(new Date(element2.date)));
        });
    }

    public loadSurveyList() {
        this.crudProvider.getElementList().subscribe(surveyList => {
            this.surveyList = [];
            surveyList.forEach(survey => {
                this.voteProvider.getVoteListBySurveyId(survey.id).subscribe(voteList => {
                    const array = [];
                    survey.count = 0;
                    voteList.forEach(vote => {
                        survey.count++;
                        vote.choiceList = this.filterCheckedChoices(vote);
                        vote.choiceList.forEach(data => {
                            array.push(data.description);
                        });
                    });
                    const counts = [];
                    array.forEach(function (x) {
                        counts[x] = (counts[x] || 0) + 1;
                    });
                    let maxCount = 0;
                    let value = '';
                    survey.choiceList.forEach(choice => {
                        if (counts[choice] > maxCount) {
                            maxCount = counts[choice];
                            value = choice;
                        }
                    });
                    survey.favorite = value;
                });
                this.surveyList.push(survey);
                this.surveyList = SurveyProvider.sortDateList<Survey>(this.surveyList);
            });
        });
    }

    filterCheckedChoices(vote: Vote): any {
        const checkedChoices = [];
        vote.choiceList.forEach(choice => {
            if (choice.checked) {
                checkedChoices.push(choice);
            }
        });
        return checkedChoices;
    }

    addSurvey(survey: Survey) {
        survey.date = moment().format();
        return this.crudProvider.addElement(survey);
    }

    deleteSurvey(id: string) {
        this.deleteSurveyVoteList(id).then(() => this.crudProvider.deleteElement(id));
    }

    updateSurvey(survey: Survey) {
        this.crudProvider.updateElement(survey);
    }

    deleteSurveyVoteList(id: string) {
        return this.afs.collection(CollectionName.Vote.toString()).ref.where('surveyId', '==', id)
            .get()
            .then(function (querySnapshot) {
                querySnapshot.forEach(function (doc) {
                    doc.ref.delete();
                });
            });
    }
}
