import {Injectable} from '@angular/core';
import {CRUDProvider} from '../CRUDProvider';
import {AngularFirestore} from 'angularfire2/firestore';
import {UserFine} from '../../models/userFine';
import {AlertProvider} from '../alert/alert';
import {User} from '../../models/user';
import {Fine} from '../../models/fine';
import * as moment from 'moment';
import {CollectionName} from '../../models/collectionEnum';
import {AngularFireAuth} from 'angularfire2/auth';
import {Observable} from 'rxjs/Observable';
import {take} from 'rxjs/operators';
import {KegelAbend} from '../../models/kegelabend';
import {Rank} from '../../models/rank';
import {UserStat} from '../../models/user_stat';

/*
  Generated class for the KegelAbendFinesProvider provider.

  See https://angular.io/guide/dependency-injection for more info on providers
  and Angular DI.
*/
@Injectable({providedIn: 'root'})
export class KegelAbendFineProvider {

    crudProvider: CRUDProvider<UserFine>;

    kegelAbendFineList: UserFine[] = [];

    userStatsDict = {};


    constructor(private afs: AngularFirestore, private alertProvider: AlertProvider, private afAuth: AngularFireAuth) {
        this.crudProvider = new CRUDProvider<UserFine>(CollectionName.KegelAbendFine.toString(), afs, afAuth);
        // this.loadKegelAbendFineList();
    }

    public loadKegelAbendFineList() {
        this.afs.collection<UserFine>(CollectionName.KegelAbendFine).valueChanges().subscribe(kegelAbendFineList => {
            this.kegelAbendFineList = [];
            kegelAbendFineList.forEach(fine => {
                this.kegelAbendFineList.push(fine);
            });
            // this.startProcessing();
        });
    }

    public async addFine(user: User, fine: Fine, kegelAbendId: string) {
        fine.date = moment().format();
        const userFine: UserFine = {
            userId: user.id,
            fine: fine,
            kegelAbendId: kegelAbendId,
        };
        this.crudProvider.addElement(userFine);
        // Update kegelabend doc
        /* TODO const kegelAbend: KegelAbend = await this.afs.collection<KegelAbend>(CollectionName.KegelAbend.toString())
            .doc(kegelAbendId).valueChanges().pipe(take(1)).toPromise();
        kegelAbend.totalFineCount++;
        kegelAbend.totalFineValue += fine.value;
        kegelAbend.avgFineValue = kegelAbend.totalFineValue / kegelAbend.totalFineCount;*/
        // TODO Update this.afs.collection<KegelAbend>(CollectionName.KegelAbend.toString())

        // Update user rank doc
        // this.afs.collection(CollectionName.UserRank).doc(user.id)
        // TODO totalCount + totalValue

        // Increase user rank subcollection by 1
        // this.afs.collection(CollectionName.UserRank).doc(user.id).collection('fines').doc(fine.id)
        // TODO If fine.description==='Pudel' increase kegelAbend.pudelCount by one
    }

    deleteFine(id: string) {
        // TODO If Pudel decrease pudel count
        this.crudProvider.deleteElement(id);
        /* TODO const kegelAbend: KegelAbend = await this.afs.collection<KegelAbend>(CollectionName.KegelAbend.toString())
            .doc(...).valueChanges().pipe(take(1)).toPromise();
        kegelAbend.totalFineCount--;
        kegelAbend.totalFineValue -= fine.value;
        kegelAbend.avgFineValue = kegelAbend.totalFineValue / kegelAbend.totalFineCount;*/
        // TODO Update Kegelabend
    }

    getFineName(kegelAbendFineId: string): string {
        let kegelAbendFineName = '';
        this.kegelAbendFineList.forEach(kegelAbendFine => {
            if (kegelAbendFine.id === kegelAbendFineId) {
                kegelAbendFineName = kegelAbendFine.fine.description;
            }
        });
        return kegelAbendFineName;
    }

    getMostRecentFiveFines(kegelAbendId: string): Observable<UserFine[]> {
        return this.afs.collection<UserFine>(CollectionName.KegelAbendFine.toString(), afs =>
            afs.where('kegelAbendId', '==', kegelAbendId).orderBy('fine.date', 'desc').limit(5)).valueChanges();
    }

    getRanks(userFineValues: UserFine[]): Rank[] {
        if (!!userFineValues) {
            const rankList: Rank[] = [];
            const fineDict = [];
            const dict = {};

            // Count how many times each user got a fine
            userFineValues.forEach(userFine => {
                fineDict.push(userFine.fine.description);
                if (dict[userFine.fine.description] == null) {
                    dict[userFine.fine.description] = [];
                }
                if (!!dict[userFine.fine.description][userFine.userId]) {
                    dict[userFine.fine.description][userFine.userId] += 1;
                } else {
                    dict[userFine.fine.description][userFine.userId] = 1;
                }
            });

            // Distinct the fine descriptions
            const fineSet = new Set(fineDict);


            Object.keys(dict).forEach(fineDescription => {
                const userIds = Object.keys(dict[fineDescription]);
                // To fill the podium their should be at least three user in the list
                if (userIds.length >= 3) {
                    // Sort the list so that the user with the highest count is at index 0
                    userIds.sort((id1, id2) => dict[fineDescription][id2] - dict[fineDescription][id1]);
                    // To avoid a podium with three users havening a count of 1 the fineCount should be at least 3
                    if (dict[fineDescription][userIds[0]] >= 3) {
                        rankList.push({
                            description: fineDescription,
                            first: {userId: userIds[0], count: dict[fineDescription][userIds[0]]},
                            second: {userId: userIds[1], count: dict[fineDescription][userIds[1]]},
                            third: {userId: userIds[2], count: dict[fineDescription][userIds[2]]}
                        });
                    }
                }
            });
            return rankList;
        } else {
            return [];
        }
    }
}


// Following code was used for generating the userStats
/*startProcessing() {
    console.log('started');
    this.kegelAbendFineList.forEach(userFine => {
        this.updateUserStat(userFine);
    });
    console.log(this.userStatsDict);
    const userIdList = Object.keys(this.userStatsDict);
    userIdList.forEach(userId => {
        this.afs.collection(CollectionName.UserStat).doc(userId).set(this.userStatsDict[userId]);
    });
}
updateUserStat(userFine: UserFine) {
    // const userStat = this.afs.collection<UserStat>(CollectionName.UserStat).doc(userFine.userId).valueChanges().pipe(take(1))
    // .toPromise();
    // {fineName: string, count: number, totalValue: number}
    if (!!this.userStatsDict[userFine.userId]) {
        // update userStat
        const userStat = this.userStatsDict[userFine.userId];
        userStat.totalFineCount += 1;
        userStat.totalFineValue += Number(userFine.fine.value);
        const index = userStat.fineList.findIndex(fine => fine.fineName === userFine.fine.description);
        if (index >= 0) {
            const fineStat = userStat.fineList[index];
            fineStat.count += 1;
            fineStat.totalValue += Number(userFine.fine.value);
            userStat.fineList[index] = fineStat;
        } else {
            userStat.fineList.push({fineName: userFine.fine.description, count: 1, totalValue: Number(userFine.fine.value)});
        }

        this.userStatsDict[userFine.userId] = userStat;

    } else {
        // create new userStat
        const userStat = {} as UserStat;
        userStat.userId = userFine.userId;
        userStat.totalFineCount = 1;
        userStat.totalFineValue = Number(userFine.fine.value);
        userStat.fineList = [];
        userStat.fineList.push({fineName: userFine.fine.description, count: 1, totalValue: Number(userFine.fine.value)});
        this.userStatsDict[userFine.userId] = userStat;
    }
}*/
