/**
 * Created by MartinThissen on 14.04.2018.
 */
export const environment = {
  production: false,
  firebase: {
    apiKey: 'AIzaSyDrDRxhQWujgPCU8b3Qs845Tvk9Jt9oOjg',
    authDomain: 'kegelapp-173808.firebaseapp.com',
    databaseURL: 'https://kegelapp-173808.firebaseio.com',
    projectId: 'kegelapp-173808',
    storageBucket: 'kegelapp-173808.appspot.com',
    messagingSenderId: '592261539325'
  }
};
