import {Injectable} from '@angular/core';
import {CRUDProvider} from '../CRUDProvider';
import {Notice} from '../../models/notice';
import {AngularFirestore} from 'angularfire2/firestore';
import {CollectionName} from '../../models/collectionEnum';
import {AngularFireAuth} from 'angularfire2/auth';

/*
  Generated class for the NoticeProvider provider.

  See https://angular.io/guide/dependency-injection for more info on providers
  and Angular DI.
*/
@Injectable({providedIn: 'root'})
export class NoticeProvider {

    crudProvider: CRUDProvider<Notice>;


    constructor(private afs: AngularFirestore, private afAuth: AngularFireAuth) {
        this.crudProvider = new CRUDProvider<Notice>(CollectionName.Notice.toString(), afs, afAuth);
    }

    public addNotice(notice: Notice) {
        this.crudProvider.addElement(notice);
    }

    updateNotice(notice: Notice) {
        this.crudProvider.updateElement(notice);
    }

    deleteNotice(id: string) {
        return this.crudProvider.deleteElement(id);
    }
}
