import {Injectable} from '@angular/core';
import {CollectionName} from '../../models/collectionEnum';
import {User} from '../../models/user';
import {CRUDProvider} from '../CRUDProvider';
import {AngularFirestore} from 'angularfire2/firestore';
import {take} from 'rxjs/operators';
import {UserFine} from '../../models/userFine';
import {KegelAbend} from '../../models/kegelabend';
import {UserStat} from '../../models/user_stat';
import {Role} from '../../models/roles.enum';
import {compareDesc} from 'date-fns';
import {AngularFireAuth} from 'angularfire2/auth';
import {UserProvider} from '../user/user';
import {Constants} from '../constants';
import {Gaussian} from 'ts-gaussian';
import {strict} from 'assert';
import {BehaviorSubject} from 'rxjs/BehaviorSubject';

/*
  Generated class for the UserStatsProvider provider.

  See https://angular.io/guide/dependency-injection for more info on providers
  and Angular DI.
*/
@Injectable({providedIn: 'root'})
export class UserStatsProvider {

    fineList = ['Pudel', 'Glocke', 'Wurffang', 'Spitzname', 'Kugel', 'Falsche Reihenfolge', 'Verspätung', 'Antrinken',
        'Telefonieren', 'Summenspiel', 'Gruppenspiel', 'Baustelle', '4-Gewinnt', 'Fehlüberreichung', '1. Verlierer', '2. Verlierer'];
    userStatsList: UserStat[] = [];
    userStatsDict = {};
    userRanksDict = {};
    memberList: User[] = [];


    dataLoaded = new BehaviorSubject(false);

    constructor(private afs: AngularFirestore, private afAuth: AngularFireAuth, private userProvider: UserProvider) {
        this.loadData();
    }

    async loadData() {
        // Load all userStats
        this.afs.collection<UserStat>(CollectionName.UserStat).valueChanges().subscribe(userStatsList => {
            this.userStatsList = [];
            this.userStatsDict = {};
            userStatsList.forEach(userStat => {
                this.userStatsList.push(userStat);
                this.userStatsDict[userStat.userId] = userStat;
            });
            this.calculateRanks();
        });

        // Subscribe to the memberListSubject -> UserStats are only available for members
        this.userProvider.memberListSubject.subscribe(memberList => {
            this.memberList = memberList;
            this.calculateRanks();
        });
    }

    calculateRanks() {
        this.fineList.forEach(fineName => this.calculateRanksByFineName(fineName));
    }

    // Fills the userRankDict with a array of rankValues for a given fineName (which is the key of the array)
    calculateRanksByFineName(fineName: string) {
        if (!!this.memberList) {
            // Calculate average and standard deviation for the normal distribution
            const fineValues = this.getFineValues(fineName);
            const avg = Constants.average(fineValues);
            const sd = Constants.standardDeviation(fineValues);
            const distribution = new Gaussian(avg, sd * sd);

            // Fill the userRankDict for the specific field
            this.memberList.forEach(user => {
                if (!!this.userStatsDict[user.id]) {
                    const fineIndex = this.userStatsDict[user.id].fineList.find(fineStat => fineStat.fineName === fineName);
                    let fineCount = 0;
                    const strikeCount = this.userStatsDict[user.id].strikeCount;
                    let totalValue = 0;
                    if (!!fineIndex) {
                        fineCount = Number(fineIndex.count);
                        totalValue = Number(fineIndex.totalValue);
                    }
                    const userPresentCount = Number(this.userStatsDict[user.id].presentCount);
                    const rankValue = (1 - distribution.cdf(fineCount / userPresentCount)) * 100;
                    if (this.userRanksDict[fineName] == null) {
                        this.userRanksDict[fineName] = [
                            {userId: user.id, value: rankValue, fineCount: fineCount, strikeCount: strikeCount, totalValue: totalValue}
                        ];
                    } else {
                        this.userRanksDict[fineName].push(
                            {userId: user.id, value: rankValue, fineCount: fineCount, strikeCount: strikeCount, totalValue: totalValue}
                        );
                    }
                }
            });
            this.dataLoaded.next(true);
        }
    }

    // Returns an array of values calculated with (fineCount / userPresentCount) for each user.
    private getFineValues(fineName: string) {
        const fineValues = [];
        this.memberList.forEach(user => {
            if (!!this.userStatsDict[user.id]) {
                const fineIndex = this.userStatsDict[user.id].fineList.find(fineStat => fineStat.fineName === fineName);
                let fineCount = 0;
                if (!!fineIndex) {
                    fineCount = Number(fineIndex.count);
                }
                const userPresentCount = Number(this.userStatsDict[user.id].presentCount);
                fineValues.push(fineCount / userPresentCount);
            }
        });
        return fineValues;
    }

    getRankValue(userId: string, fineName) {
        if (!!this.userRanksDict[fineName]) {
            const rankValue = Math.ceil(this.userRanksDict[fineName].find(userRank => userRank.userId === userId).value);
            return Math.min(rankValue + 20, 99);
        }
        return 0;
    }

    getTop3RankValue(fineName) {
        if (!!this.userRanksDict[fineName]) {
            let list = this.userRanksDict[fineName];
            list = list.sort((rank1, rank2) => rank1.value - rank2.value);
            return list.slice(0, 3);
        }
        return [];
    }

    getWurfgenauigkeit(userId: string) {
        this.getTop3RankValue('Pudel');
        const rankSum = this.getRankValue(userId, 'Pudel')
            + this.getRankValue(userId, 'Glocke')
            + this.getRankValue(userId, 'Wurffang');
        return Math.ceil(rankSum / 3);
    }

    getDiscipline(userId: string) {
        const rankSum = this.getRankValue(userId, 'Spitzname')
            + this.getRankValue(userId, 'Kugel')
            + this.getRankValue(userId, 'Falsche Reihenfolge')
            + this.getRankValue(userId, 'Verspätung')
            + this.getRankValue(userId, 'Antrinken')
            + this.getRankValue(userId, 'Baustelle')
            + this.getRankValue(userId, 'Fehlüberreichung');
        return Math.ceil(rankSum / 7);
    }

    getGewinner(userId: string) {
        const rankSum = this.getRankValue(userId, 'Telefonieren')
            + this.getRankValue(userId, 'Summenspiel')
            + this.getRankValue(userId, 'Gruppenspiel')
            + this.getRankValue(userId, '4-Gewinnt')
            + this.getRankValue(userId, '1. Verlierer')
            + this.getRankValue(userId, '2. Verlierer');
        return Math.ceil(rankSum / 6);
    }

    // Ab hier wird es etwas unschön :D

    getPudelTrait(userId) {
        for (const userRank of this.getTop3RankValue('Pudel')) {
            if (userId === userRank.userId) {
                const name = this.userProvider.getNameForUser(userRank.userId);
                return {
                    trait: 'Pudelkönig',
                    subtext: 'Mit einem Streufeuer - ähnlich dem G36 - feuert ' + name + ' regelmäßig in Vollen. Zur Verteidigung ' +
                    'seines eigenen Kontos sollte er bei insgesamt ' + String(userRank.fineCount) + ' Fehlwürfen dringend an seiner ' +
                    'Zieltechnik arbeiten.'
                };
            }
        }
        return null;
    }

    getKugelTrait(userId) {
        for (const userRank of this.getTop3RankValue('Kugel')) {
            if (userId === userRank.userId) {
                const name = this.userProvider.getNameForUser(userRank.userId);
                return {
                    trait: 'Verpeilt',
                    subtext: 'Während des Kegelns pflegt ' + name + ' eine schiere Menge anderer Hobbys außer dem eigentlichen Kegeln. ' +
                    'Schon ' + String(userRank.fineCount) + ' Mal musste man ihm die Kugel an seinen Platz bringen.'
                };
            }
        }
        return null;
    }

    getTelefonierenTrait(userId) {
        for (const userRank of this.getTop3RankValue('Telefonieren')) {
            if (userId === userRank.userId) {
                const name = this.userProvider.getNameForUser(userRank.userId);
                return {
                    trait: 'Call-Center',
                    subtext: 'Bei ' + name + ' brennen regelmäßig die Telefonleitungen durch. In bester Telekom-Manier verechnete ihm der' +
                    ' Kegelclub seine hohe Netzauslastung mit insgesamt ' + userRank.totalValue.toFixed(2) + ' €'
                };
            }
        }
        return null;
    }

    getFirstVerliererTrait(userId) {
        for (const userRank of this.getTop3RankValue('1. Verlierer')) {
            if (userId === userRank.userId) {
                const name = this.userProvider.getNameForUser(userRank.userId);
                return {
                    trait: 'Looser',
                    subtext: name + ' werden regelmäßig die Grenzen seiner keglerischen Fähigkeiten aufgezeigt. Schon ' +
                    String(userRank.fineCount) + ' Mal ' + 'musste ' + name + ' ein Spiel mit roter Laterne beenden.'
                };
            }
        }
        return null;
    }

    getSecondVerliererTrait(userId) {
        for (const userRank of this.getTop3RankValue('2. Verlierer')) {
            if (userId === userRank.userId) {
                const name = this.userProvider.getNameForUser(userRank.userId);
                return {
                    trait: '2. Verlierer',
                    subtext: name + ' ist wahrlich kein 31er, schon ' + String(userRank.fineCount) + ' Mal stand er dem 1. Verlierer als ' +
                    'kleiner Bruder zur Seite.'
                };
            }
        }
        return null;
    }

    getGlockenTrait(userId) {
        for (const userRank of this.getTop3RankValue('Glocke')) {
            if (userId === userRank.userId) {
                const name = this.userProvider.getNameForUser(userRank.userId);
                return {
                    trait: 'Glockenläuter',
                    subtext: 'Insgesamt ' + String(userRank.fineCount) + ' Mal stellte ' + name + ' seine Qualitäten als ' +
                    'potientieller Messdiener heraus und ließ die Glocken beim Kegeln läuten.'
                };
            }
        }
        return null;
    }

    getBaustelleTrait(userId) {
        for (const userRank of this.getTop3RankValue('Baustelle')) {
            if (userId === userRank.userId) {
                const name = this.userProvider.getNameForUser(userRank.userId);
                return {
                    trait: 'Bauarbeiter',
                    subtext: name + ' entpuppt sich gerne mal als Sammler der goldenen Flüssigkeit, schon ' + String(userRank.fineCount) +
                    ' Mal wurde er dabei erwischt. Oder um es mit Ronny Schäfer zu halten: "Von mir aus könnt hier der ganze Tisch voll ' +
                    'mit Bier stehen!".'
                };
            }
        }
        return null;
    }

    getSpitznameTrait(userId) {
        for (const userRank of this.getTop3RankValue('Spitzname')) {
            if (userId === userRank.userId) {
                const name = this.userProvider.getNameForUser(userRank.userId);
                return {
                    trait: 'Namenskönig',
                    subtext: 'Sich den Namen neuer Bekanntschaften zu merken ist die eine Sache, sich beim Kegeln ändernde Namen zu' +
                    'behalten die andere. Insgesamt ' + String(userRank.fineCount) + ' Mal hat das zur kognitiven Überforderung bei ' +
                    name + ' geführt.'
                };
            }
        }
        return null;
    }

    getFalscheReihenfolgeTrait(userId) {
        for (const userRank of this.getTop3RankValue('Falsche Reihenfolge')) {
            if (userId === userRank.userId) {
                const name = this.userProvider.getNameForUser(userRank.userId);
                return {
                    trait: 'Drängler',
                    subtext: 'Ein Mix aus Ungeduld und Verplantheit verkörpert durch ' + name + '. Schon ' + String(userRank.fineCount) +
                    ' Mal hat ' + name + ' in der falschen Reihenfolge gekegelt.'
                };
            }
        }
        return null;
    }

    getVerspaetungTrait(userId) {
        for (const userRank of this.getTop3RankValue('Verspätung')) {
            if (userId === userRank.userId) {
                const name = this.userProvider.getNameForUser(userRank.userId);
                return {
                    trait: 'Sonnenuhr',
                    subtext: 'Nicht selten genehmigt ' + name + ' sich eine akademische Viertelstunde beim Kegeln. Blöd nur, dass ' +
                    'Dynamo Pins Verspätung mit 3,00 € sanktioniert und ' + name + ' dies schon ' + String(userRank.fineCount) +
                    ' Mal widerfahren ist.'
                };
            }
        }
        return null;
    }

    getTraitList(userId: string) {
        let traitList = [];
        traitList.push(this.getFirstVerliererTrait(userId));
        traitList.push(this.getSecondVerliererTrait(userId));
        traitList.push(this.getKugelTrait(userId));
        traitList.push(this.getPudelTrait(userId));
        traitList.push(this.getTelefonierenTrait(userId));
        traitList.push(this.getGlockenTrait(userId));
        traitList.push(this.getBaustelleTrait(userId));
        traitList.push(this.getSpitznameTrait(userId));
        traitList.push(this.getFalscheReihenfolgeTrait(userId));
        traitList.push(this.getVerspaetungTrait(userId));
        traitList = traitList.filter(trait => trait != null);
        return traitList;
    }
}
