import { ExcelUserService } from './../excelUser/excel-user.service';
import { ExcelUser } from './../../models/excelUser';
import {Injectable} from '@angular/core';
import {CRUDProvider} from '../CRUDProvider';
import {Payment} from '../../models/payment';
import {AngularFirestore} from 'angularfire2/firestore';
import {AlertProvider} from '../alert/alert';
import {CollectionName} from '../../models/collectionEnum';
import * as moment from 'moment';
import * as eachDay from 'date-fns/each_day';
import * as startOfMonth from 'date-fns/start_of_month';
import * as endOfMonth from 'date-fns/end_of_month';
import * as isSameDay from 'date-fns/is_same_day';
import {User} from '../../models/user';
import {UserProvider} from '../user/user';
import {Constants} from '../constants';
import {map, take} from 'rxjs/operators';
import {Observable} from 'rxjs/Observable';
import {AngularFireAuth} from 'angularfire2/auth';
import {AlertController} from '@ionic/angular';

/*
 Generated class for the PaymentProvider provider.

 See https://angular.io/guide/dependency-injection for more info on providers
 and Angular DI.
 */
@Injectable({providedIn: 'root'}/**/)
export class PaymentProvider {

    crudProvider: CRUDProvider<Payment>;

    constructor(private afs: AngularFirestore, private afAuth: AngularFireAuth, private alertCtrl: AlertController, private excelUserService: ExcelUserService) {
        this.crudProvider = new CRUDProvider<Payment>(CollectionName.Payment.toString(), afs, afAuth);
    }

    public addPayment(payment: Payment) {
        payment.date = moment().format();
        this.crudProvider.addElement(payment).then(data => console.log(data));
    }

    public updatePayment(payment: Payment) {
        payment.date = moment().format();
        this.crudProvider.updateElement(payment);
    }

    async deletePayment(id: string) {
        this.crudProvider.deleteElement(id);
    }


    // Wird momentan vom Profile-Grid aufgerufen, wenn selectedUser gesetzt wird
    getOpenFinesForUser(user: User): Observable<Payment[]> {
        return this.afs.collection<Payment>(CollectionName.Payment, ref => ref
            .where('fromId', '==', user.id)
            .where('paid', '==', false))
            .snapshotChanges().pipe(
                map(changes => {
                    return changes.map(
                        a => {
                            const data = a.payload.doc.data() as Payment;
                            data.id = a.payload.doc.id;
                            return data;
                        });
                }));
    }
}
