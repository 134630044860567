import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {AuthGuard} from './auth.guard';

const routes: Routes = [
  { path: '', redirectTo: 'home', pathMatch: 'full'},
  { path: 'home', loadChildren: './home/home.module#HomePageModule', canActivate: [AuthGuard] },
  { path: 'login', loadChildren: './login/login.module#LoginPageModule' },
  { path: 'register', loadChildren: './register/register.module#RegisterPageModule' },
  { path: 'profile/:id', loadChildren: './profile/profile.module#ProfilePageModule', canActivate: [AuthGuard] },
  { path: 'kegelmitglieder', loadChildren: './kegelmitglieder/kegelmitglieder.module#KegelmitgliederPageModule', canActivate: [AuthGuard] },
  { path: 'kegelabendlist', loadChildren: './kegelabendlist/kegelabendlist.module#KegelabendlistPageModule', canActivate: [AuthGuard] },
  { path: 'kegelabend/:id', loadChildren: './kegelabend/kegelabend.module#KegelabendPageModule', canActivate: [AuthGuard] },
  { path: 'userfinelist/:userId', loadChildren: './user-fine-list/user-fine-list.module#UserFineListPageModule', canActivate: [AuthGuard] },
  { path: 'kegel-abend-creation-modal', loadChildren: './kegel-abend-creation-modal/kegel-abend-creation-modal.module#KegelAbendCreationModalPageModule', canActivate: [AuthGuard] },
  { path: 'kegel-abend-user-list-popover', loadChildren: './kegel-abend-user-list-popover/kegel-abend-user-list-popover.module#KegelAbendUserListPopoverPageModule', canActivate: [AuthGuard] },
  { path: 'kegel-abend-options-popover', loadChildren: './kegel-abend-options-popover/kegel-abend-options-popover.module#KegelAbendOptionsPopoverPageModule', canActivate: [AuthGuard] },
  { path: 'kegel-abend-fine-form', loadChildren: './kegel-abend-fine-form/kegel-abend-fine-form.module#KegelAbendFineFormPageModule', canActivate: [AuthGuard] },
  { path: 'kegel-abend-add-guest-modal', loadChildren: './kegel-abend-add-guest-modal/kegel-abend-add-guest-modal.module#KegelAbendAddGuestModalPageModule' },
  { path: 'kegel-abend-multiple-fine-modal', loadChildren: './kegel-abend-multiple-fine-modal/kegel-abend-multiple-fine-modal.module#KegelAbendMultipleFineModalPageModule', canActivate: [AuthGuard] },
  { path: 'MultipleFinePopover', loadChildren: './multiple-fine-popover/multiple-fine-popover.module#MultipleFinePopoverPageModule', canActivate: [AuthGuard] },
  { path: 'create-user', loadChildren: './create-user/create-user.module#CreateUserPageModule', canActivate: [AuthGuard] },
  { path: 'kegel-abend-user-detail/:userId/:kegelAbendId', loadChildren: './kegel-abend-user-detail/kegel-abend-user-detail.module#KegelAbendUserDetailPageModule', canActivate: [AuthGuard] },
  { path: 'administration-tab', loadChildren: './administration-tab/administration-tab.module#AdministrationTabPageModule', canActivate: [AuthGuard] },
  { path: 'create-fine', loadChildren: './create-fine/create-fine.module#CreateFinePageModule', canActivate: [AuthGuard] },
  { path: 'user-role-popover', loadChildren: './user-role-popover/user-role-popover.module#UserRolePopoverPageModule', canActivate: [AuthGuard] },
  { path: 'user-linking-modal', loadChildren: './user-linking-modal/user-linking-modal.module#UserLinkingModalPageModule', canActivate: [AuthGuard] },
  { path: 'image-viewer-modal', loadChildren: './image-viewer-modal/image-viewer-modal.module#ImageViewerModalPageModule', canActivate: [AuthGuard] },
  { path: 'delete-kegel-abend-confirmation', loadChildren: './delete-kegel-abend-confirmation/delete-kegel-abend-confirmation.module#DeleteKegelAbendConfirmationPageModule' },
  { path: 'finance', loadChildren: './finance/finance.module#FinancePageModule', canActivate: [AuthGuard] },  { path: 'PaymentFormModal', loadChildren: './payment-form-modal/payment-form-modal.module#PaymentFormModalPageModule' },
  { path: 'PaymentCategoryFormModal', loadChildren: './payment-category-form-modal/payment-category-form-modal.module#PaymentCategoryFormModalPageModule' }


];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
