import { Game } from './../../models/game';
import {Injectable} from '@angular/core';
import {AngularFirestore} from 'angularfire2/firestore';
import {CRUDProvider} from '../CRUDProvider';
import {CollectionName} from '../../models/collectionEnum';
import {AngularFireAuth} from 'angularfire2/auth';

/*
 Generated class for the FineProvider provider.

 See https://angular.io/guide/dependency-injection for more info on providers
 and Angular DI.
 */
@Injectable({providedIn: 'root'})
export class GameProvider {

    crudProvider: CRUDProvider<Game>;

    gameList = [];

    constructor(private afs: AngularFirestore, private afAuth: AngularFireAuth) {
        this.crudProvider = new CRUDProvider<Game>(CollectionName.Games.toString(), afs, afAuth);
        this.loadGameList();
    }

    public loadGameList() {
        this.crudProvider.getElementList().subscribe(gameList => {
            this.gameList = [];
            gameList.forEach(game => {
                this.gameList.push(game);
            });
            this.gameList.sort((game1, game2) => new Date(game2.date).getTime() - new Date(game1.date).getTime());
        });
    }

    public addGame(game: Game) {
        this.crudProvider.addElement(game);
    }

    public updateGame(game: Game) {
        this.crudProvider.updateElement(game);
    }

    deleteGame(id: string) {
        this.crudProvider.deleteElement(id);
    }

    getGameDescription(gameId: string): string {
        let gameDescription = '';
        this.gameList.forEach(game => {
            if (game.id === gameId) {
                gameDescription = game.description;
            }
        });
        return gameDescription;
    }
}
