import {Component, Input, OnInit} from '@angular/core';
import {User} from '../models/user';
import {ImageProvider} from '../services/image/image';
import {UserProvider} from '../services/user/user';
import {take} from 'rxjs/operators';
import {ImageViewerModalPage} from '../image-viewer-modal/image-viewer-modal.page';
import {ModalController} from '@ionic/angular';

@Component({
    selector: 'app-user-avatar',
    templateUrl: './user-avatar.component.html',
    styleUrls: ['./user-avatar.component.scss']
})
export class UserAvatarComponent implements OnInit {

    user: User;

    @Input()
    size: number;

    constructor(private imageProvider: ImageProvider, private userProvider: UserProvider, private modalCtrl: ModalController) {
    }

    @Input()
    set userId(userId: string) {
        this.loadUser(userId);
    }

    async loadUser(id) {
        // load the specific user object
        const user = await this.userProvider.getUserObservableById(id).pipe(take(1)).toPromise();
        if (!!user) {
            // if the user object has the imagePath property, load the imageUrl for the user
            const imagePath = user.imagePath;
            if (!!imagePath) {
                this.imageProvider.getImage(imagePath)
                    .then(imageUrl => {
                        user.imageUrl = imageUrl;
                        this.user = user;
                    })
                    .catch(error => {
                        user.imageUrl = null;
                        this.user = user;
                        // TODO Error handling console.log(error);
                    });
            } else {
                this.user = user;
            }
        }
    }

    getPixelSuffix(number: number) {
        return String(number) + 'px';
    }

    async openImageViewer(event, imageUrl: string) {
        event.stopPropagation();
        const modal = await this.modalCtrl.create({
            component: ImageViewerModalPage,
            componentProps: {
                url: imageUrl
            }
        });
        await modal.present();
    }

    ngOnInit() {
    }

}
