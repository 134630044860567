/**
 * Created by MartinThissen on 24.05.2018.
 */
import {UserPosition} from './user-position';

export enum CollectionName {
  KegelAbendFine = 'kegelAbendFines',
  Fine = 'fines',
  User = 'users',
  KegelAbend = 'kegelAbend',
  Appointment = 'appointment',
  Notice = 'notice',
  FirebaseUser = 'firebaseUser',
  Survey = 'survey',
  Vote = 'vote',
  AppointmentVote = 'appointmentVote',
  Payment = 'payment',
  Feed = 'feed',
  UserRank = 'userRank',
  UserPosition = 'userPosition',
  UserStat = 'userStats',
  Games = 'games',
  PaymentCategory = 'paymentCategory'
}
