import {Injectable} from '@angular/core';
import {FirebaseUser} from '../../models/firebaseUser';
import {CRUDProvider} from '../CRUDProvider';
import {AngularFirestore} from 'angularfire2/firestore';
import {CollectionName} from '../../models/collectionEnum';
import {AlertProvider} from '../alert/alert';
import {AngularFireAuth} from 'angularfire2/auth';
/*
  Generated class for the FirebaseUserProvider provider.

  See https://angular.io/guide/dependency-injection for more info on providers
  and Angular DI.
*/
@Injectable({providedIn: 'root'})
export class FirebaseUserProvider {

    firebaseUserList: FirebaseUser[] = [];


    crudProvider: CRUDProvider<FirebaseUser>;

    constructor(private afs: AngularFirestore, private alertProvider: AlertProvider, private afAuth: AngularFireAuth) {
        this.crudProvider = new CRUDProvider<FirebaseUser>(CollectionName.FirebaseUser.toString(), afs, afAuth);
    }

    public loadFirebaseUserList() {
        this.crudProvider.getElementList().subscribe(userList => {
            this.firebaseUserList = [];
            userList.forEach(user => {
                this.firebaseUserList.push(user);
            });
        });
    }

    public addUser(firebaseUser: FirebaseUser) {
        console.log(firebaseUser);
        return this.crudProvider.addElementWithId(firebaseUser, firebaseUser.firebaseId);
    }

    updateUser(firebaseUser: FirebaseUser) {
        if (firebaseUser.id != null) {
            this.crudProvider.updateElement(firebaseUser);
        }
    }

    deleteFirebaseUser(id: string) {
        this.crudProvider.deleteElement(id);
    }

    getNameForUser(userId: string): string{
        let name = '';
        this.firebaseUserList.forEach(user => {
            if (user.id === userId) {
                name += user.name;
            }
        });
        return name;
    }
}
