import {Injectable} from '@angular/core';
import {AlertController, ToastController} from '@ionic/angular';

/*
 Generated class for the AlertProvider provider.

 See https://angular.io/guide/dependency-injection for more info on providers
 and Angular DI.
 */
@Injectable({providedIn: 'root'})
export class AlertProvider {

    constructor(public alertCtrl: AlertController, private toastCtrl: ToastController) {
    }

    async confirmDialog(msg: string, callback: () => void) {
        if (msg != null) {
            msg += ' wirklich löschen?';
        }
        if (msg == null) {
            msg = 'Wirklich löschen?';
        }

        const alert = await this.alertCtrl.create({
            header: msg,
            buttons: [{
                text: 'Ja',
                handler: () => {
                    callback();
                }
            }, {
                text: 'Nein',
            }]
        });
        await alert.present();
    }

    async confirmDialogWithCustomMessage(msg: string, callback: () => void) {
        const alert = await this.alertCtrl.create({
            header: msg,
            buttons: [{
                text: 'Ja',
                handler: () => {
                    callback();
                }
            },
                {
                    text: 'Nein'
                }]
        });
        await alert.present();
    }

    async toastMessage(msg: string) {
        const toast = await this.toastCtrl.create({
            message: msg,
            duration: 1500,
            position: 'bottom'
        });
        await toast.present();
    }
}



