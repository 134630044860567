import {Component, Input, OnInit} from '@angular/core';
import {ModalController, NavParams} from '@ionic/angular';

@Component({
  selector: 'app-image-viewer-modal',
  templateUrl: './image-viewer-modal.page.html',
  styleUrls: ['./image-viewer-modal.page.scss'],
})
export class ImageViewerModalPage implements OnInit {

  url = '';
  constructor(private modalController: ModalController, private navParams: NavParams) { }

  ngOnInit() {
      this.url = this.navParams.get('url');
  }

  close() {
    this.modalController.dismiss();
  }

}
