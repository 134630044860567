import {Injectable} from '@angular/core';
import {User} from '../../models/user';
import {CollectionName} from '../../models/collectionEnum';
import {AngularFirestore} from 'angularfire2/firestore';
import {UserFine} from '../../models/userfine';
import {CRUDProvider} from '../CRUDProvider';
import {ImageProvider} from '../image/image';
import {KegelAbend} from '../../models/kegelabend';
import {take} from 'rxjs/operators';
import {AuthProvider} from '../auth/auth';
import {AngularFireAuth} from 'angularfire2/auth';

/*
  Generated class for the StatsProvider provider.

  See https://angular.io/guide/dependency-injection for more info on providers
  and Angular DI.
*/
@Injectable({providedIn: 'root'})
export class StatsProvider {

    crudProviderUser: CRUDProvider<User>;
    crudProviderFines: CRUDProvider<UserFine>;
    crudProviderKegelabend: CRUDProvider<KegelAbend>;

    userList: User[] = [];
    fineList: UserFine[] = []; // Hier werden alle Strafen gespeichert
    kegelAbendList: KegelAbend[] = [];

    userFineCount: { // Hier werden die Strafenzähler der User gespeichert
        user: User,
        fineCount: { 'fineName': string, 'count': number }[],
        currentFineCount?: { 'fineName': string, 'count': number }
    }[] = [];

    // Hier können weitere Statistiken eingefügt werden
    shownFines: string[] = ['Pudel', '1. Verlierer', '2. Verlierer', 'Kugel', 'Spitzname', 'Glocke', 'Verspätung'];


    totalAttendance: number;
    loaded = false;
    avg = false; // Zur Prüfung, ob Durchschnittswerte oder Totalwerte angezeigt werden sollen

    constructor(private authProvider: AuthProvider, private imageProvider: ImageProvider, private afs: AngularFirestore,
                private afAuth: AngularFireAuth) {
        /*this.crudProviderUser = new CRUDProvider<User>(CollectionName.User.toString(), afs , afAuth);
        this.crudProviderFines = new CRUDProvider<UserFine>(CollectionName.KegelAbendFine.toString(), afs, afAuth);
        this.crudProviderKegelabend = new CRUDProvider<KegelAbend>(CollectionName.KegelAbend.toString(), afs, afAuth);*/
        // this.loadStats(); // Lädt erst alle erforderlichen Listen (UserList, FineList, KegelabendList)
    }

    ionViewDidLoad() {
    }

    async loadStats() {
        this.userList = await this.loadUserList();
        // Gastkegler werden ausgefiltert
        this.userList = this.userList.filter(user => this.authProvider.isMember(user));
        this.fineList = await this.loadFineList();
        this.kegelAbendList = await this.loadKegelAbendList();

        // UserFineCountListe wird gefüllt mit Usern
        this.userList.forEach(user => {
            this.userFineCount.push({
                'user': user,
                'fineCount': [] // FineCountArray wird später initialisiert
            });
        });

        // FineCount wird gefüllt in UserFineCountListe
        this.shownFines.forEach(fine => {
            this.userFineCount.forEach(user => {
                user.fineCount.push({
                    'fineName': fine,
                    'count': this.getFineCountForUser(user.user, fine)
                });
            });
        });

        // Anwesenheit wird berechnet und in userFineCount gespeichert
        this.userFineCount.forEach(user => {
            let counter = 0;
            this.kegelAbendList.forEach(kegelabend =>
                kegelabend.userList.forEach(user2 => {
                        if (user.user.id === user2.id && !user2.isAbsent) {
                            counter++;
                        }
                    }
                ));
            user.user.attendance = counter;
        });
        this.loaded = true;
    }

    public loadUserList(): Promise<User[]> {
        return this.crudProviderUser.getElementList().pipe(take(1)).toPromise();
    }

    public loadFineList(): Promise<UserFine[]> {
        return this.crudProviderFines.getElementList().pipe(take(1)).toPromise();
    }

    public loadKegelAbendList(): Promise<KegelAbend[]> {
        return this.crudProviderKegelabend.getElementList().pipe(take(1)).toPromise();
    }

    public getFineCountForUser(user: User, description: String): number {
        const tmp = this.fineList.filter(fine =>
            ((fine.userId === user.id) && (fine.fine.description === description))
        );
        return tmp.length;
    }

    // Gibt die FineCountList für spezifischen Nutzer aus
    public getFineCountListForUser(user2: User) {
        return this.userFineCount.filter(user => user.user === user2);
    }

    // Gibt die FineCountList für spezifischen Nutzer aus
    public getFineCountListForFine(fineName: string) {
        this.userFineCount.forEach(userCount => {
            userCount.fineCount.forEach(fine => {
                if (fine.fineName === fineName) {
                    userCount.currentFineCount = {fineName: fineName, count: fine.count};
                }
            });
        });
        return this.userFineCount;
    }

    public getTotalStats(fineNam: string) {
        if (!this.avg) {
            return this.fineList.filter(fine => fine.fine.description === fineNam).length;
        } else {
            // Berechnet Durchschnittswert
            return this.fineList.filter(fine => fine.fine.description === fineNam).length / this.kegelAbendList.length / 10;
        }
    }

    setAverage(avg: boolean) {
        if (avg && !this.avg) { // Berechnet Durchschnittswerte, wenn momentan Gesamtstrafen angezeigt werden
            this.userFineCount.forEach(user => {
                user.fineCount.map(fineCount => fineCount.count = fineCount.count / user.user.attendance);
                this.avg = true;
                return;
            });
        } else if (!avg && this.avg) {  // Berechnet Totalwerte zurück, wenn momentan Durchschnittswerte angezeigt werden
            this.userFineCount.forEach(user => {
                user.fineCount.map(fineCount => fineCount.count = fineCount.count * user.user.attendance);
                this.avg = false;
                return;
            });
        }
    }

    /*
    Statistiken für Feed-Kegelabend
    */
    getEveningInfo(kegelAbendId: string) {
        const abend = {
            userList: [],
            fineList: [],
            dypisAnwesend: 0,
            dypisAbwesend: 0,
            gastKegler: 0,
            totalFines: 0,
            avgFines: 0,
            totalFinesCount: 0,
            totalPudelCount: 0,
            besterKegler: '',
            schlechtesterKegler: ''
        };
        abend.userList = this.kegelAbendList.filter(evening => evening.id === kegelAbendId)[0].userList;
        abend.dypisAnwesend = abend.userList.filter(user => !user.isAbsent && this.authProvider.isMember(user)).length;
        abend.dypisAbwesend = 10 - abend.dypisAnwesend; // TODO: 10 ersetzen
        abend.gastKegler = abend.userList.filter(user => !this.authProvider.isMember(user)).length;

        // StrafenListe des Abends wird geladen
        abend.fineList = this.fineList.filter(fine => fine.kegelAbendId === kegelAbendId);

        // Alle Strafen werden auf totalFines addiert
        abend.fineList.forEach(fine => abend.totalFines += Number(fine.fine.value));

        // Beiträge werden zu totalFines addiert
        abend.totalFines += (this.kegelAbendList.filter(evening => evening.id === kegelAbendId)[0].beitrag * (abend.dypisAnwesend +
            abend.gastKegler));
        // Der Durchschnitt wird so oft addiert, wie Leute gefehlt haben
        abend.avgFines = Math.ceil(abend.totalFines / (abend.gastKegler + abend.dypisAnwesend));
        abend.totalFines += (abend.dypisAbwesend * abend.avgFines);

        // Gesamtanzahl an Strafen
        abend.totalFinesCount = abend.fineList.length;
        abend.totalPudelCount = abend.fineList.filter(fine => fine.fine.description === 'Pudel').length;

        // Berechnung bester/schlechtester Kegler
        let max = 0;
        let min = 999;
        abend.userList
        // Filtert abwesende heraus
            .filter(user => !user.isAbsent).forEach(user => {
            let sum = 0;
            abend.fineList.filter(fine => fine.userId === user.id)
                .forEach(fine => sum += Number(fine.fine.value));
            if (sum > max) {
                abend.schlechtesterKegler = user.name;
                max = sum;
            }
            if (sum < min) {
                abend.besterKegler = user.name;
                min = sum;
            }
        });
        return abend;
    }
}
