import {Component} from '@angular/core';

import {NavController, Platform} from '@ionic/angular';
import {SplashScreen} from '@ionic-native/splash-screen/ngx';
import {StatusBar} from '@ionic-native/status-bar/ngx';
import {AuthProvider} from './services/auth/auth';
import {Router} from '@angular/router';
import {Constants} from './services/constants';
import {CollectionName} from './models/collectionEnum';

@Component({
    selector: 'app-root',
    templateUrl: 'app.component.html',
    styleUrls: ['app.component.scss']
})
export class AppComponent {

    pages: Array<{ title: string, component: any, icon: string, roleMethod: boolean, collection?: CollectionName }>;

    constructor(
        private platform: Platform,
        private splashScreen: SplashScreen,
        private statusBar: StatusBar,
        public authProvider: AuthProvider,
        private navController: NavController,
        private router: Router
    ) {
        this.initializeApp();

        authProvider.userSubject.subscribe(user => {
            if (user != null) {
                const isMember = this.authProvider.isMember(user);
                const isController = this.authProvider.isController(user);
                this.pages = Constants.getPages(isMember, isController);
            }
        });
    }

    logout() {
        this.authProvider.logout()
            .then(() => this.navController.navigateRoot('/login'));
    }

    openPage(page) {
        this.router.navigateByUrl(page.component);
    }

    initializeApp() {
        this.platform.ready().then(() => {
            this.statusBar.styleDefault();
            this.splashScreen.hide();
        });
    }
}
