import {Injectable} from '@angular/core';
import {AngularFirestore} from 'angularfire2/firestore';
import {KegelAbend} from '../../models/kegelabend';
import {CRUDProvider} from '../CRUDProvider';
import {User} from '../../models/user';
import {Observable} from 'rxjs';
import {DocumentReference} from '@firebase/firestore-types';
import {UserFine} from '../../models/userFine';
import {ImageProvider} from '../image/image';
import {Notice} from '../../models/notice';
import {KegelAbendFineProvider} from '../kegel-abend-fine/kegel-abend-fine';
import {CollectionName} from '../../models/collectionEnum';
import {Identifiable} from '../../models/identifiable';
import {SurveyProvider} from '../survey/survey';
import {UserProvider} from '../user/user';
import {FeedProvider} from '../feed/feed';
import {map} from 'rxjs/internal/operators';
import {AlertProvider} from '../alert/alert';
import {AngularFireAuth} from 'angularfire2/auth';
import * as getYear from 'date-fns/get_year';
import {Constants} from '../constants';
import * as moment from 'moment';
import {UserPosition} from '../../models/user-position';
import {Fine} from '../../models/fine';


/*
 Generated class for the KegelAbendProvider provider.

 See https://angular.io/guide/dependency-injection for more info on providers
 and Angular DI.
 */
@Injectable({providedIn: 'root'})
export class KegelAbendProvider {

    kegelAbendList: KegelAbend[] = [];

    kegelAbendByYearList = [];
    crudProvider: CRUDProvider<KegelAbend>;

    constructor(private afs: AngularFirestore, private alertProvider: AlertProvider, private imageProvider: ImageProvider,
                private kegelAbendFineProvider: KegelAbendFineProvider, private userProvider: UserProvider,
                private feedProvider: FeedProvider, private afAuth: AngularFireAuth) {
        this.crudProvider = new CRUDProvider<KegelAbend>(CollectionName.KegelAbend.toString(), afs, afAuth);
        this.loadKegelAbendList();
    }

    async addKegelAbend(kegelAbend: KegelAbend): Promise<DocumentReference> {
        const response = await this.crudProvider.addElement(kegelAbend);
        this.feedProvider.addFeed({referencedId: response.id, collectionName: CollectionName.KegelAbend});
        // Increment user presence by 1
        // userProvider.getMemberList().forEach(
        // this.afs.collection(CollectionName.UserRank).doc(userId));
        return response;
    }

    /*increaseStrikeCount(userId: string) {
        this.afs.collection(CollectionName.UserRank).doc(userId).strikeCount;
    }*/

    updateKegelAbend(kegelAbend: KegelAbend) {
        if (kegelAbend.id != null) {
            this.crudProvider.updateElement(kegelAbend);
        }
    }

    async deleteKegelAbend(id: string) {
        await this.crudProvider.deleteReferencedElements(CollectionName.KegelAbendFine, 'kegelAbendId', id);
        await this.crudProvider.deleteReferencedElements(CollectionName.UserPosition, 'kegelAbendId', id);
        await this.crudProvider.deleteReferencedElements(CollectionName.Payment, 'kegelAbendId', id);
        await this.crudProvider.deleteReferencedElements(CollectionName.Notice, 'kegelAbendId', id);
        await this.crudProvider.deleteReferencedElements(CollectionName.Feed, 'referencedId', id);
        await this.crudProvider.deleteElement(id);
    }

    loadKegelAbendList() {
        this.afs.collection<KegelAbend>(CollectionName.KegelAbend, ref => ref.orderBy('date', 'desc'))
            .snapshotChanges().pipe(
            map(changes => {
                return changes.map(
                    a => {
                        const data = a.payload.doc.data() as KegelAbend;
                        data.id = a.payload.doc.id;
                        return data;
                    });
            })).subscribe(kegelAbendList => {
            this.kegelAbendList = kegelAbendList;
            this.groupKegelAbendListByYear();
            this.loadImageUrls();
        });
    }

    private loadImageUrls() {
        this.kegelAbendList.forEach(kegelAbend => {
            if (!!kegelAbend.imagePath) {
                this.imageProvider.getImage(kegelAbend.imagePath).then(imageUrl => kegelAbend.imageUrl = imageUrl);
            }
        });
    }

    private groupKegelAbendListByYear() {
        this.kegelAbendByYearList = [];
        // Add additional field year to the array
        this.kegelAbendList.forEach(kegelAbend => kegelAbend['year'] = getYear(kegelAbend.date));
        // Group the list by the year property
        const kegelAbendByYearDict = Constants.groupBy(this.kegelAbendList, 'year');
        for (const key of Object.keys(kegelAbendByYearDict)) {
            this.kegelAbendByYearList.push({year: key, kegelAbendList: kegelAbendByYearDict[key]});
        }
        // Reverse the direction so that the current year comes first
        this.kegelAbendByYearList.reverse();
    }

    getKegelAbendById(id: string): Observable<KegelAbend> {
        return this.crudProvider.getElement(id);
    }

    // Observable für alle Strafen eines Kegelabends
    getFinesForKegelAbendId(id: string): Observable<UserFine[]> {
        return this.afs.collection<UserFine>(CollectionName.KegelAbendFine, ref => ref.where('kegelAbendId', '==', id))
            .snapshotChanges().pipe(
            map(changes => {
                return changes.map(
                    a => {
                        const data = a.payload.doc.data() as UserFine;
                        data.id = a.payload.doc.id;
                        return data;
                    });
            }));
    }

    // Anzahl einer spezifischen Strafe eines spezifischen Keglers für eine gegebene Strafenliste
    getFineCountByUserId(fineList: UserFine[], fine: Fine, userId: string): number {
        let counter = 0;
        fineList.forEach(userFine => {
            if (!!fine && userFine.userId === userId && (userFine.fine.id === fine.id || userFine.fine.description === fine.description)) {
                counter++;
            }
        });
        return counter;
    }

    // Rückgabe der Durschnitts für einen gegebene Strafenliste sowie Anzahl anwesender Kegler
    calculateAverage(fineList: UserFine[], presentUserCount: number) {
        let counter = 0;
        fineList.forEach(kegelAbendFine => {
            counter += Number(kegelAbendFine.fine.value);
        });
        return Math.ceil(counter / presentUserCount * 2) / 2;
    }

    // Berechnung der Anzahl anwesender Kegler eines Kegelabends
    getPresentUserCount(kegelAbend: KegelAbend) {
        let counter = 0;
        kegelAbend.userList.forEach(user => {
            if (!user.isAbsent) {
                counter++;
            }
        });
        return counter;
    }

    loadRelationCollection<T extends Identifiable>(collection: CollectionName, kegelAbendId: string): Observable<T[]> {
        return this.afs.collection(collection.toString(), ref => ref.where('kegelAbendId', '==', kegelAbendId))
            .snapshotChanges()
            .pipe(map(changes => {
                return changes
                    .map(
                        a => {
                            const data = a.payload.doc.data() as T;
                            data.id = a.payload.doc.id;
                            return data;
                        });
            }));
    }

    sortKegelAbend(kegelAbend: KegelAbend, average: number, fineList: UserFine[], beitrag: number) {
        return kegelAbend.userList.sort((user1, user2) => {
            return this.getValueForUser(user1, average, fineList, beitrag) - this.getValueForUser(user2, average, fineList, beitrag);
        });
    }

    // Berechne den Wert aller Strafen eines Nutzer für einen Kegelabend (Abwesende erhalten den Durchschnitt)
    getValueForUser(user: User, average: number, fineList: UserFine[], beitrag: number): number {
        let counter = 0;
        if (user.isAbsent) {
            counter = average;
        } else {
            fineList.forEach(userFine => {
                if (userFine.userId === user.id) {
                    counter += Number(userFine.fine.value);
                }
            });
        }
        return counter + beitrag;
    }

    getTotalAmountOfValues(kegelAbend: KegelAbend, average: number, fineList: UserFine[], beitrag: number) {
        let totalCount = 0;
        for (const user of kegelAbend.userList) {
            totalCount += this.getValueForUser(user, average, fineList, beitrag);
        }
        return totalCount;
    }

    getMostRecentFiveKegelAbende(limit: number): Observable<KegelAbend[]> {
        return this.afs.collection<KegelAbend>(CollectionName.KegelAbend.toString(), afs =>
            afs.where('date', '<=', moment().format())
                .where('completed', '==', true)
                .orderBy('date', 'desc')
                .limit(limit))
            .valueChanges();
    }

    persistUserPosition(userPosition: UserPosition) {
        this.afs.collection(CollectionName.UserPosition).add(userPosition);
    }

    getUserPosition(userId: string, kegelAbendId: string) {
        return this.afs.collection<UserPosition>(CollectionName.UserPosition.toString(), ref => ref
            .where('userId', '==', userId)
            .where('kegelAbendId', '==', kegelAbendId)).valueChanges();
    }
}
