import {Injectable} from '@angular/core';
import * as firebase from 'firebase';
import {AlertProvider} from '../alert/alert';
import {LoadingController} from '@ionic/angular';

/*
 Generated class for the ImageProvider provider.

 See https://angular.io/guide/dependency-injection for more info on providers
 and Angular DI.
 */
@Injectable({providedIn: 'root'})
export class ImageProvider {


    constructor(public loadingCtrl: LoadingController, private alertProvider: AlertProvider) {
    }

    async presentLoading() {
        const loading = await this.loadingCtrl.create({
            message: 'Please wait...',
            spinner: 'dots',
        });
        await loading.present();
        return loading;
    }

    async uploadImage(image: string, userId: string) {
        // const loadModal = await this.presentLoading();
        /*setTimeout(() => loadModal.dismiss().then(() =>{
          this.alertProvider.confirmDialogWithCustomMessage("Konnte nicht hochgeladen werden");
        }), 10000);*/

        const storageRef = firebase.storage().ref();
        const imageName = this.generateUUID();
        const imageRef = storageRef.child(`${userId}/${imageName}.jpg`);
        return imageRef.putString(image, 'data_url').then(function (snapshot) {
            console.log(snapshot);
            // loadModal.dismiss();
            return snapshot;
        });
    }

    getImage(imagePath: string): Promise<any> {
        const storageRef = firebase.storage().ref();
        const imageRef = storageRef.child(imagePath);
        return imageRef.getDownloadURL();
    }

    async deleteImage(imagePath: string): Promise<any> {
        const storageRef = firebase.storage().ref();
        const imageRef = await storageRef.child(imagePath);
        const url = await imageRef.getDownloadURL();
        return imageRef.delete();
    }

    private generateUUID(): string {
        function s4() {
            return Math.floor((1 + Math.random()) * 0x10000)
                .toString(16)
                .substring(1);
        }

        return s4() + s4() + '-' + s4() + '-' + s4() + '-' +
            s4() + '-' + s4() + s4() + s4();
    }

}
