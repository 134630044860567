import {AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument} from 'angularfire2/firestore';
import {Observable, of} from 'rxjs';
import {map, take, takeUntil} from 'rxjs/operators';
import {Identifiable} from '../models/identifiable';
import {DocumentReference} from '@firebase/firestore-types';
import {CollectionName} from '../models/collectionEnum';
import {AngularFireAuth} from 'angularfire2/auth';


/**
 * Created by MartinThissen on 08.05.2018.
 */
export class CRUDProvider<T extends Identifiable> {

    collection: AngularFirestoreCollection<T>; // Firestore collection

    collectionName: String;

    constructor(collectionName: string, private afs: AngularFirestore, private afAuth: AngularFireAuth) {
        this.collectionName = collectionName;
        this.collection = this.afs.collection(collectionName);
    }

    public getElement(id: string): Observable<T> {
        if (id != null) {
            const document: AngularFirestoreDocument<T> = this.collection.doc<T>(id);
            return document.snapshotChanges().pipe(
                map(changes => {
                    const data = changes.payload.data() as T;
                    data.id = changes.payload.data().id;
                    return data;
                }));
        } else {
            return of(null);
        }
    }

    public getElementList(): Observable<T[]> {
        return this.collection.snapshotChanges().pipe(
            map(changes => {
                return changes.map(
                    a => {
                        const data = a.payload.doc.data() as T;
                        data.id = a.payload.doc.id;
                        return data;
                    });
            }));
    }

    public addElement(element: T): Promise<DocumentReference> {
        return this.collection.add(element);
    }

    public addElementWithId(element: T, id: string): Promise<any> {
        return this.collection.doc(id).set(element);
    }

    public updateElement(element: T) {
        return this.collection.doc(element.id).update(element);
    }

    deleteElement(id: string) {
        return this.collection.doc(id).delete();
    }

    public deleteReferencedElements(collection: CollectionName, key: string, id: string,): Promise<any> {
        return this.afs.collection(collection.toString()).ref.where(key, '==', id)
            .get()
            .then(function (querySnapshot) {
                querySnapshot.forEach(function (doc) {
                    doc.ref.delete();
                });
            });
    }
}
