import {Injectable} from '@angular/core';
import {AngularFirestore} from 'angularfire2/firestore';
import {User} from '../../models/user';
import {CRUDProvider} from '../CRUDProvider';
import {AuthProvider} from '../auth/auth';
import {CollectionName} from '../../models/collectionEnum';
import {ImageProvider} from '../image/image';
import { StatsProvider } from '../stats/stats';
import {AlertProvider} from '../alert/alert';
import {map, take, takeUntil} from 'rxjs/operators';
import {AngularFireAuth} from 'angularfire2/auth';
import {BehaviorSubject, Observable} from 'rxjs/index';
import {KegelAbend} from '../../models/kegelabend';

/*
  Generated class for the UserProvider provider.

  See https://angular.io/guide/dependency-injection for more info on providers
  and Angular DI.
*/
@Injectable({ providedIn: 'root' })
export class UserProvider {

  userList: User[] = [];
  memberList: User[] = [];
  guestList: User[] = [];

  memberListSubject: BehaviorSubject<User[]> = new BehaviorSubject<User[]>(null);

  selectedUser: User;

  crudProvider: CRUDProvider<User>;

  constructor(private afs: AngularFirestore, private authProvider: AuthProvider, private alertProvider: AlertProvider,
              private imageProvider: ImageProvider, private afAuth: AngularFireAuth) {
    this.crudProvider = new CRUDProvider<User>(CollectionName.User.toString(), afs, afAuth);
    this.loadUserList();
  }

  public loadUserList() {
    this.crudProvider.getElementList().subscribe(userList => {
      this.userList = [];
      this.memberList = [];
      this.guestList = [];
      userList.forEach(user => {
        if (this.authProvider.isMember(user)) {
          this.memberList.push(user);
        } else {
          this.guestList.push(user);
        }
        this.userList.push(user);
      });
      this.memberListSubject.next(this.memberList);
    });
  }
  public getUserObservableById(id: string) {
    // If member variabel userList is filled return the wanted user from it, otherwise load the user object from the database
    if (!!this.userList) {
      const filteredUser = this.userList.filter(user => user.id === id);
      if (filteredUser.length === 1) {
        return new BehaviorSubject<User>(filteredUser[0]);
      }
    }
    return this.crudProvider.getElement(id);
  }

  public getUserById(id: string) {
    return this.userList.filter(user => user.id === id)[0];
  }

  public addUser(user: User) {
    console.log(user);
    return this.crudProvider.addElement(user);
  }

  updateUser(user: User) {
    if (user.id != null) {
      this.crudProvider.updateElement(user);
    }
  }

  deleteUser(id: string) {
    this.crudProvider.deleteElement(id);
  }

  getPropertyForUser(userId: string, propertyName: string): string {
    let property = '';
    this.userList.forEach(user => {
      if (user.id === userId) {
        property += user[propertyName];
      }
    });
    return property;
  }

  getOpenFinesForUser(userId: string): number {
    let openFines = 0;
    this.userList.forEach(user => {
      if (user.id === userId) {
        openFines = user.openFines;
      }
    });
    return openFines;
  }

  resetFinesForUser(userId: string) {
    this.userList.forEach(user => {
      if (user.id === userId) {
        user.openFines = 0;
        this.crudProvider.updateElement(user);
      }
    });
  }

  getNameForUser(userId: string): string {
        let name = '';
        this.userList.forEach(user => {
            if (user.id === userId) {
                name += user.name;
            }
        });
        return name;
    }
}
