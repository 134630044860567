import {Injectable} from '@angular/core';
import {AngularFirestore} from 'angularfire2/firestore';
import {Fine} from '../../models/fine';
import {CRUDProvider} from '../CRUDProvider';
import {CollectionName} from '../../models/collectionEnum';
import {AlertProvider} from '../alert/alert';
import {AngularFireAuth} from 'angularfire2/auth';

/*
 Generated class for the FineProvider provider.

 See https://angular.io/guide/dependency-injection for more info on providers
 and Angular DI.
 */
@Injectable({providedIn: 'root'})
export class FineProvider {

    crudProvider: CRUDProvider<Fine>;

    fineList: Fine[] = [];
    fineValueList = [];

    constructor(private afs: AngularFirestore, private alertProvider: AlertProvider, private afAuth: AngularFireAuth) {
        this.crudProvider = new CRUDProvider<Fine>(CollectionName.Fine.toString(), afs, afAuth);
    }

    public loadFineList() {
        this.crudProvider.getElementList().subscribe(fineList => {
            this.fineList = [];
            this.fineValueList = [];
            fineList.forEach(fine => {
                this.fineList.push(fine);
                this.fineValueList.push(+fine.value);
            });
            this.fineValueList.sort((f1, f2) => f1 - f2);
            this.fineValueList = Array.from(new Set(this.fineValueList));
        });
    }

    public getFineByDescription(fineName:string):Fine {
        return this.fineList.find(fine => fine.description == fineName);
    }

    public addFine(fine: Fine) {
        this.crudProvider.addElement(fine);
    }

    public updateFine(fine: Fine) {
        this.crudProvider.updateElement(fine);
    }

    deletePenaltyScale(id: string) {
        this.crudProvider.deleteElement(id);
    }

    getFineName(fineId: string): string {
        let fineName = '';
        this.fineList.forEach(fine => {
            if (fine.id === fineId) {
                fineName = fine.description;
            }
        });
        return fineName;
    }
}
